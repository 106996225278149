@tailwind base;

@tailwind components;

@tailwind utilities;

body{
  height: 100vh;
  width: 100vw;
}

#root{
  height: 100vh;
  width: 100vw;
}
input[type='text']:focus { box-shadow: none; }




.estilo-botones{
  background-color: white;
  border-color: #D1CAB8;
  border-radius: none;
  border-style: solid;
  border-width: 2px;
}
.estilo-botones:hover{
  background-color: white;
  border-color: #717171;
  border-radius: none;
  border-style: solid;
  border-width: 2px;
}


.text-titulo{
  color: #504E4E;
  font-size: 80px;
  font-family: Cormorant;
  font-weight: 500;
}

.text-subtitulo{
  color: #504E4E;
  font-size: 30px;
  font-family: Work Sans;
  font-weight: 500;
}

.text-pregunta{
  color: #504E4E;
  font-size: 38px;
  font-family: Cormorant;
  font-weight: 500;
}

.text-respuesta{
  color: #717171;
  font-size: 24px;
  font-weight: 500;
  font-family: Work Sans;
}

.text-titulo-m{
  color: #504E4E;
  font-family: Cormorant;
  font-weight: 500;
  font-size: 80px;
}

.text-titulo-720{
  color: #504E4E;
  font-family: Cormorant;
  font-weight: 500;
  font-size: 3rem;
}

.text-inputs{
  color: #717171;
  font-weight: 400;
  font-size: 20px;
  font-family: Work Sans;
}

.text-parrafo1{
  color: #504E4E;
  font-weight: 400;
  font-size: 40px;
  font-family: Cormorant;
}

.text-parrafo-720{
  color: #504E4E;
  font-weight: 400;
  font-size: 25px;
  font-family: Cormorant;
}

.font-cormorant{
  font-family: Cormorant;
}

.font-cormorant-400{
  font-family: Cormorant;
  font-weight: 400;
}

.font-cormorant-500{
  font-family: Cormorant;
  font-weight: 500;
}

.font-cormorant-bold{
  font-family: Cormorant;
  font-weight: 700;
}

.inner-div{
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}
*,*:focus,*:hover{
  outline:none;
}
.select-memo{
  font-family: Work Sans;
  color: #717171;
  font-weight: 400;
  border-bottom-width: 1px;
  border-color: #707070;
  border-right: none;
  border-top: none;
  border-left: none;
  background-color: #F9F8F4;
}
.select-memo:focus{
  border-color: #707070;
  border-right: none;
  border-top: none;
  border-left: none;
  box-shadow: none;
}

.text-label{
  color: #504E4E;
  font-weight: 500;
  font-family: Cormorant;
  font-size: 30px;
}
.text-label-reg{
  color: #504E4E;
  font-weight: 400;
  font-family: Cormorant;
  font-size: 30px;
}
.text-label2{
  color: #504E4E;
  font-weight: 500;
  font-family: Cormorant;
  font-size: 26px;
}
.text-parrafo1{
  color: #504E4E;
  font-weight: 400;
  font-size: 40px;
  font-family: Cormorant;
}

.text-navbar{
  color: #504E4E;
  font-family: Cormorant;
  font-size: 40px;
  font-weight: 600;
}

.text-titulo{
  color: #504E4E;
  font-size: 80px;
  font-family: Cormorant;
  font-weight: 500;
}
.text-planes{
  color: #504E4E;
  font-family: Cormorant;
  font-weight: 600;
  font-size: 50px
}
.text-inputs{
  color: #717171;
  font-weight: 400;
  font-size: 20px;
  font-family: Work Sans;
}

.border-memo{
  border-radius: 9999px;
  background-color: #FFFFFF;
  border-width: 1px;
  border-color: #707070;
}
.border-memo:focus{
  box-shadow: none;
  border-width: 2px;
  border-color: #707070;
}

.text-botones{
  color: #717171;
  font-weight: 500;
  font-family: Work Sans;
  letter-spacing: 3.6px;
  font-size: 18px;
}
.text-botones2{
  color: #717171;
  font-weight: 500;
  font-family: Work Sans;
}
.estilo-botones{
  background-color: white;
  border-color: #D1CAB8;
  border-radius: none;
  border-style: solid;
  border-width: 2px;
}
.estilo-botones:hover{
  background-color: white;
  border-color: #717171;
  border-radius: none;
  border-style: solid;
  border-width: 2px;
}

.bg-page{
  background-color: #F9F8F4;
}

.text-cabecera-tabla{
  font-size: 26px;
  color: #504E4E;
  font-weight: 500;
  font-family: Work Sans;
}
.text-tabla{
  font-size: 24px;
  color: #504E4ED0;
  font-weight: 400;
  font-family: Work Sans;
}
.borde-tabla{
  border-bottom-width: 2px;
  border-color: #707070;
}

/*      
            SCROLL CON ESTILOS 
        NO TOCAR NI EDITAR !!!!!!!!!!!!
                  >:c
                                */ 

::-webkit-scrollbar{
  width: 8px;
  display:block;
  margin-right: 8px;
}

::-webkit-scrollbar-track-piece {
  background: white; 
}
 
::-webkit-scrollbar-thumb {
  background: #ADA99E; 
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #504E4E; 
}
::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}

/*              FIN SCROLL           */ 




/*                 SWEET ALERT
              STYLES !!!     */


.swal2-confirm {
  background-color: white !important;
  border: solid #D1CAB8 3px !important;
  border-radius: none !important;
  border-style: solid !important;
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  color: #717171 !important;
  font-weight: 500 !important;
  font-family: Work Sans !important;
  letter-spacing: 0.75px !important;
  font-size: 18px !important;
  box-shadow: none !important;
}
.swal2-confirm:hover {
  background-color: rgba(255, 255, 255, 0.322) !important;
  border: solid #717171 3px !important;
  border-radius: none !important;
}

.swal2-border {
  box-sizing: border-box;
  border: solid #D1CAB8 3px;
  padding: 3px;
  border-radius: 0;
  padding-bottom: 1.75%;
  
}

.swal2-title{
  color: #504E4E;
  font-weight: 600;
  font-family: Cormorant;
  font-size: 36px;
}

.swal2-closebtn {
  padding-left: 1px;
  height: 29px;
  width: 29px;
  border-radius: 999px;
  background-color: #D1CAB8;
  color: #FFFFFF;
  font-size: 32px;
}
.swal2-closebtn:hover {
  padding-left: 1px;
  height: 29px;
  width: 29px;
  border-radius: 999px;
  background-color: #717171;
  color: #FFFFFF;
  font-size: 32px;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 500px;
  width: auto;
  background-color: #717171;
  color: #fff;
  font-size: 21px;
  text-align: center;
  border-radius: 6px;
  padding-left: 2px;
  padding-right: 2px;
  overflow: visible;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 1;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
